@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800;900&display=swap");

:root {
	--poppins: "Poppins", "Roboto", sans-serif;
	--openSans: "Open Sans", "Roboto", sans-serif;
}

a,
a:visited,
a:focus,
a:hover {
	text-decoration: none;
	color: inherit;
}

/*body {*/
/*  background: #13161e;*/
/*}*/

body::-webkit-scrollbar {
	display: none;
}

#root {
	overflow: hidden;
	height: 100%;
}

.grecaptcha-badge {
	visibility: hidden;
}

.vjs-loading-spinner:after {
	display: none;
}

.vjs-control-text {
	position: absolute;
	top: 50px;
	left: -50px;
	width: 150px;
}
